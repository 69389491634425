import React from "react";
import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <div className={styles.wizardFooter}>
      <div className={styles.footerItem}>
        Associate Professor Deirdre E McGhee
      </div> 
      <div className={styles.footerItem}>Emeritus Professor Julie R Steele</div>
    </div>
  );
};

export default Footer;
