import React from "react";
import styles from "../pages/about.module.css";
import ROUTES from "../constants/routes";
import { Link } from "react-router-dom";

const ContactThanks = () => {
  return (
    <div className={styles.aboutPage}>
      <div className={styles.aboutHeader}>
        <h1 className={styles.aboutHeaderTitle}>Let us know what you think</h1>
        <div className={styles.aboutHeaderSubtitle}>
          <h2>
            Your feedback is valuable to us! <br /> Please let us know if this
            application has been helpful to you.
          </h2>
        </div>
      </div>

      <div className={styles.aboutContainer}>
        <h3>
          Thanks for your feedback! <br />
          <div style={{ margin: "1rem 0" }}>
            <Link to={ROUTES.CONTACT}>Send us another message.</Link>
          </div>
        </h3>
      </div>
    </div>
  );
};

export default ContactThanks;
