import React from "react";
import styles from "./imageMap.module.css";
import { ImageMap } from "@qiuz/react-image-map";
import { FaInfoCircle } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";

export const BraImageMap = (img) => {
  const onMapClick = (area, index) => {
    const labelArea = document.getElementById(
      `labelArea${img.img.src}${index}`
    );

    if (labelArea.style.display === "none" || labelArea.style.display === "") {
      labelArea.style.display = "block";
    } else {
      labelArea.style.display = "none";
    }

    for (let i = 0; i < 6; i++) {
      if (i !== index) {
        const labelAreaToHide = document.getElementById(
          `labelArea${img.img.src}${i}`
        );

        if (labelAreaToHide) {
          labelAreaToHide.style.display = "none";
        }
      }
    }
  };

  const onClose = (index) => {
    const labelArea = document.getElementById(
      `labelArea${img.img.src}${index}`
    );
    labelArea.style.display = "none";
  };

  const mapArea = img.img.labels.map((l) => {
    return {
      left: l.imageMapCoordinates[0],
      top: l.imageMapCoordinates[1],
      height: "25px",
      width: "25px",
      render: () => (
        <div className={styles.container}>
          <FaInfoCircle size="25px" />
        </div>
      ),
    };
  });

  return (
    <div className={styles.imageMapContainer}>
      <ImageMap
        className="usage-map"
        src={require(`../../../assets/recommended/${img.img.src}.jpg`)}
        map={mapArea}
        onMapClick={onMapClick}
      />

      {img.img.labels.map((i, index) => {
        return (
          <div
            id={`labelArea${img.img.src}${index}`}
            className={styles.labelArea}
          >
            <div className={styles.labelHeader}>
              <h2>{i.name}</h2>
              <div onClick={() => onClose(index)}>
                <RiCloseCircleLine size="25px" />
              </div>
            </div>

            <ul>
              {i.text.map((li) => {
                return <li>{li}</li>;
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default BraImageMap;
