import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import AboutPage from "./pages/about";
import SportsBra from "./pages/sportsBra";
import Contact from "./pages/contact";
import Thanks from "./pages/contactThanks";
import ROUTES from "./constants/routes";
import { SiteHeader } from "./components/siteHeader";
import { WizardProvider } from "./context/wizardContext";
import Footer from "./components/SportsBra/footer";
import ScrollToTop from "./utilities/scrollToTop";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <WizardProvider>
            <SiteHeader />
            <Switch>
              <Route path={ROUTES.HOME} component={Home} exact={true} />
              <Route path={ROUTES.ABOUT} component={AboutPage} exact={true} />
              <Route path={ROUTES.CONTACT} component={Contact} exact={true} />
              <Route path={ROUTES.THANKS} component={Thanks} exact={true} />
              <Route
                path={ROUTES.SPORTSBRA}
                component={SportsBra}
                exact={true}
              />
              <Route
                path={ROUTES.RECOMMENDED}
                component={SportsBra}
                exact={true}
              />
            </Switch>
          </WizardProvider>
        </ScrollToTop>
      </Router>
      <Footer />
    </>
  );
}

export default App;
