import React from "react";
import { FaStickyNote } from "react-icons/fa";
import { useWizardContextState } from "../../context/wizardContext";
import styles from "./puttingOnYourBra.module.css";
import VideoCaption from "./videoCaption";

export const PuttingOnYourBra = () => {
  const vidRef = React.useRef(null);
  const [tick, setTick] = React.useState();

  const { cup } = useWizardContextState();

  let videoSrc = "";
  let videoPoster = "";
  if (cup <= "D") {
    videoSrc = require(`../../assets/video/SmallMediumBreastedPutOnBraCorrectlyFinalCut.mp4`);
    videoPoster = require("../../assets/video/SmallMediumBreastedPutOnBraCorrectlyFinalCut.png");
  } else {
    videoSrc = require(`../../assets/video/LargeBreastedPutBraOnCorrectlyFinalCut.mp4`);
    videoPoster = require("../../assets/video/LargeBreastedPutBraOnCorrectlyFinalCut.png");
  }

  React.useEffect(() => {
    const tickUpdateListener = () => {
      const temp = Math.floor(Number(vidRef?.current?.currentTime || 0));
      if (temp !== tick) {
        setTick(temp);
      }
    };

    const current = vidRef.current;
    if (!window.test1 && current) {
      current.addEventListener("timeupdate", tickUpdateListener);
      window.test1 = true;
    }
    return () => {
      if (window.test1) {
        current.removeEventListener("timeupdate", tickUpdateListener);
        window.test1 = false;
      }
    };
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [tick]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "1080px", margin: "0 0.5rem" }}>
        <video
          poster={videoPoster}
          ref={vidRef}
          id="vid"
          width="100%"
          preload="metadata"
          controls
          playsInline
          muted
          autoPlay
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <VideoCaption tick={tick} cup={cup} />
      </div>

      <div className={styles.steps}>
        <ol>
          <li>Loosen the straps.</li>
          <li>
            Do the band up on the loosest fastener. This will allow you to
            adjust the tightness of the band over time because the band will
            stretch as it wears.
          </li>
          <li>Ensure all your breasts are fully within each cup.</li>
          <li>
            If the bra has underwire, make sure the underwire is sitting on your
            breastbone and ribs, NOT your breasts.
          </li>
          <li>Adjust the straps so they are the right length for you.</li>
          <li>
            Check the cups fit by sweeping your hand across the top of the bra
            cups.
          </li>
        </ol>
        <div className={styles.emphasis}>
          <div className={styles.noteIndicator}>
            <FaStickyNote size="20px" />
          </div>
          <div className={styles.note}>
            <div>For a crop top: follow Steps 3 and 5 only;</div>
            <div>For a non-underwire bra: skip Step 4.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PuttingOnYourBra;
