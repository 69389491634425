import React, { useState } from "react";
import ROUTES from "../constants/routes";
import styles from "./contactForm.module.css";
import { FaEnvelope } from "react-icons/fa";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className={styles.formContainer}>
      <iframe
        title="feedbackForm"
        name="hidden_iframe"
        id="hidden_iframe"
        style={{ display: "none" }}
        onLoad={() => {
          if (submitted) {
            window.location = ROUTES.THANKS;
          }
        }}
      />

      <form
        action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSf6cNueOHl3MqfNaXg6Dug5QwnyV20Uhk_seK8iYaBbzcqlTA/formResponse"
        method="post"
        onSubmit={() => setSubmitted(true)}
        target="hidden_iframe"
      >
        <div className={styles.inputField}>
          <label htmlFor="name">Name (optional):</label>
          <input
            type="text"
            id="name"
            name="entry.1292233804"
            placeholder="Type your name here"
          />
        </div>

        <div className={styles.inputField}>
          <label htmlFor="email">Email (optional):</label>
          <input
            type="email"
            id="email"
            name="entry.1872585612"
            placeholder="Type your email address here"
          />
        </div>

        <div className={styles.inputField}>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="entry.958910163"
            placeholder="Type your comments and suggestions here"
            required
          />
        </div>
        <button className={styles.actionButton} type="submit">
          Submit
        </button>
      </form>

      <div className={styles.emailUs}>
        <div className={styles.title}>Or you can email us directly</div>
        <div className={styles.contactPerson}>
          <div className={styles.contactName}>Deirdre E McGhee</div>
          <span>
            <FaEnvelope />
            <a className={styles.emailLink} href="mailto:dmcghee@uow.edu.au">
              dmcghee@uow.edu.au
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
