import React from "react";
import ImageWithLabels from "./WizardComponents/imageWithLabels";

export const YourRecommendedBra = (props) => {
  const { breastSupportRecommended } = props;

  let recommendedBra = breastSupportRecommended;

  return (
    <div>
      <ImageWithLabels braToDisplay={recommendedBra[0]} />

      {recommendedBra[1]?.name && (
        <ImageWithLabels
          braToDisplay={recommendedBra[1]}
          titlePrefix={"You can also try"}
        />
      )}

      {recommendedBra[2]?.name && (
        <ImageWithLabels
          braToDisplay={recommendedBra[2]}
          titlePrefix={"You can also try"}
        />
      )}
    </div>
  );
};

export default YourRecommendedBra;
