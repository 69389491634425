import React from "react";
import {
  region as userRegion,
  bandAus,
  bandEur,
  bandUK,
  ageBracket,
  cupSize,
  exerciseIntensity,
} from "../../constants/formUtils";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { useWizardContextState } from "../../context/wizardContext";
import styles from "./chooseYourBra.module.css";

export const ChooseYourBra = (props) => {
  const location = useLocation();

  React.useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const wizardContext = useWizardContextState();
  const {
    band,
    setBand,
    cup,
    setCup,
    age,
    setAge,
    intensity,
    setIntensity,
    setCurrentStep,
  } = wizardContext;

  const [region, setRegion] = React.useState("AU");
  const [pathname, setPathname] = React.useState(location.pathname);

  return (
    <div className={styles.chooseBraWrap}>
      <h2 className={styles.heading}>{props.wizardHeading}</h2>
      {props.children && <div className={styles.text}>{props.children}</div>}
      <div className={styles.form}>
        <fieldset className={styles.fieldset}>
          <legend className={styles.hidden}>Bra Size</legend>
          <div className={styles.fields}>
            <div className={styles.dropdownField}>
              {pathname === "/" ? (
                <label className={styles.home} for="userRegion">
                  Region
                </label>
              ) : (
                <label className={styles.wizard} for="userRegion">
                  Region
                </label>
              )}
              <select
                onChange={(event) => setRegion(event.target.value)}
                type="select"
                name="selectRegion"
                id="selectRegion"
                defaultValue={region}
              >
                {userRegion.map((r) => {
                  return <option>{r}</option>;
                })}
              </select>
            </div>

            <div className={styles.dropdownField}>
              {pathname === "/" ? (
                <label className={styles.home} for="userBand">
                  Band
                </label>
              ) : (
                <label className={styles.wizard} for="userBand">
                  Band
                </label>
              )}
              <select
                onChange={(event) => setBand(Number(event.target.value))}
                type="select"
                name="selectBand"
                id="selectBand"
                defaultValue={band}
              >
                {region === "AU" &&
                  bandAus.map((r) => {
                    return <option>{r}</option>;
                  })}

                {(region === "UK" || region === "US") &&
                  bandUK.map((r) => {
                    return <option>{r}</option>;
                  })}

                {region === "EU" &&
                  bandEur.map((r) => {
                    return <option>{r}</option>;
                  })}
              </select>
            </div>
            <div className={styles.dropdownField}>
              {pathname === "/" ? (
                <label className={styles.home} for="userCup">
                  Cup
                </label>
              ) : (
                <label className={styles.wizard} for="userCup">
                  Cup
                </label>
              )}
              <select
                onChange={(event) => setCup(event.target.value)}
                type="select"
                name="selectCup"
                id="selectCup"
                defaultValue={cup}
              >
                {cupSize.map((r) => {
                  return <option>{r}</option>;
                })}
              </select>
            </div>
          </div>
        </fieldset>

        <fieldset className={styles.fieldset}>
          <legend className={styles.hidden}>Age Bracket</legend>

          <div className={styles.toggleButtonContainer}>
            {pathname === "/" ? (
              <label className={styles.home} for="userAge">
                Your age bracket
              </label>
            ) : (
              <label className={styles.wizard} for="userAge">
                Your age bracket
              </label>
            )}
            <div className={styles.toggleFields}>
              {ageBracket.map((r) => {
                return (
                  <button
                    className={`${styles.toggleButton} ${
                      r === age ? styles.active : ""
                    }`}
                    onClick={() => setAge(r)}
                  >
                    {r}
                  </button>
                );
              })}
            </div>
          </div>
        </fieldset>

        <fieldset className={styles.fieldset}>
          <legend className={styles.hidden}>Exercise Intensity</legend>

          <div className={styles.toggleButtonContainer}>
            {pathname === "/" ? (
              <label className={styles.home} for="userIntensity">
                Your exercise intensity
              </label>
            ) : (
              <label className={styles.wizard} for="userIntensity">
                Your exercise intensity
              </label>
            )}
            <div className={styles.toggleFields}>
              {exerciseIntensity.map((r) => {
                return (
                  <button
                    className={`${styles.toggleButton} ${
                      r === intensity ? styles.active : ""
                    }`}
                    onClick={() => setIntensity(r)}
                  >
                    {r}
                  </button>
                );
              })}
            </div>
          </div>
        </fieldset>
        {pathname !== "/sportsbra" && (
          <Link
            to={{
              pathname: ROUTES.SPORTSBRA,
            }}
          >
            <button
              className={styles.actionButton}
              onClick={() => {
                setCurrentStep(1);
              }}
            >
              Find your bra
            </button>
          </Link>
        )}
      </div> 
    </div>
  );
};

export default ChooseYourBra;
