import { NavLink } from "react-router-dom";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import headerStyles from "./siteHeader.module.css";
import logo from "../assets/BRALogo2.jpg";
import ROUTES from "../constants/routes";
import { useWizardContextState } from "../context/wizardContext";
import { EXTERNALSITES } from "../constants/externalSites";

export const SiteHeader = () => {
  const wizardContext = useWizardContextState();

  return (
    <div className={headerStyles.header}>
      <Navbar expand="lg">
        <Navbar.Brand>
          <div className={headerStyles.logoBar}>
            <NavLink to="/">
              <img
                src={logo}
                height="70px"
                className={headerStyles.logo}
                alt="Sports Bra Logo"
              />
            </NavLink>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link>
              <NavLink
                className={headerStyles.navItem}
                activeClassName={headerStyles.active}
                to={ROUTES.HOME}
                exact
              >
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                className={headerStyles.navItem}
                to={ROUTES.ABOUT}
                activeClassName={headerStyles.active}
              >
                About the tool
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                className={headerStyles.navItem}
                activeClassName={headerStyles.active}
                to={ROUTES.CONTACT}
              >
                Contact us
              </NavLink>
            </Nav.Link>
            <NavDropdown title="More information" id="basic-nav-dropdown">
              <div className={headerStyles.guideLink}>
                <a
                  href={EXTERNALSITES.FAQ}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </div>
              <div className={headerStyles.guideLink}>
                <a
                  href={EXTERNALSITES.RESOURCES}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Breast Support Resources
                </a>
              </div>
              <div className={headerStyles.guideLink}>
                <a
                  href={EXTERNALSITES.EVIDENCE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Research Evidence
                </a>
              </div>
            </NavDropdown>
            <Nav.Link>
              <NavLink
                className={headerStyles.navItem}
                activeClassName={headerStyles.active}
                to={{
                  pathname: ROUTES.SPORTSBRA,
                }}
              >
                <button
                  className={headerStyles.sportsBraButton}
                  onClick={() => wizardContext.setCurrentStep(0)}
                >
                  SPORTS BRA TOOL
                </button>
              </NavLink>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default SiteHeader;
