import React from "react";
import BraImageMap from "./imageMap";
import styles from "./imageWithLabels.module.css";

export const ImageWithLabels = (props) => {
  const { braToDisplay, titlePrefix } = props;

  return (
    <>
      {braToDisplay?.name ? (
        <>
          <h1 className={styles.recommendationText}>{titlePrefix}</h1>
          <h2 className={styles.braName}>{braToDisplay?.name}</h2>
          <div className={styles.descriptionContainer}>
            <p className={styles.description}>{braToDisplay.description}</p>
          </div>
        </>
      ) : (
        <h2>{titlePrefix}</h2>
      )}

      <div className={styles.imagesContainer}>
        {braToDisplay.imageInfo?.map((img) => {
          return <BraImageMap img={img} />;
        })}
      </div>
    </>
  );
};

export default ImageWithLabels;
