const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  THANKS: "/thanks",
  SPORTSBRA: "/sportsbra",
  RECOMMENDED: "/sportsbra/recommended",
};

export default ROUTES;
