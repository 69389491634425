export const region = ["AU", "EU", "US", "UK"];

export const bandAus = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26];
export const bandUK = [30, 32, 34, 36, 38, 40, 42, 44, 46];
export const bandEur = [80, 85, 90, 95, 100, 105, 110, 115, 120];

export const cupSize = [
  "AA",
  "A",
  "B",
  "C",
  "D",
  "DD",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
];

export const exerciseIntensity = ["Light", "Moderate", "Intense"];

export const ageBracket = ["< 30", "30 to 45", "> 45"];

export const recommendedBra = {
  HYBRID: {
    name: "Hybrid Sports Bra",
    description:
      "The “Hybrid” style sports bra is essentially an encapsulation sports bra and a crop top combined. It looks like a crop top (on the outside at the front of the bra) BUT two separate cups can be seen on the inside of the bra. The length of the straps is adjustable and the band can open/close (i.e., has hooks, clips or a zipper).",
    imageInfo: [
      {
        src: "hybrid-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup (only visible from inside the bra)",
              "Ideally, each bra cup has extra breast support at the side and bottom of the cup",
              "Breasts are completely covered (near armpit and top of breast - high round neck), lifted AND compressed against the chest wall (strong elastic fabric)",
              "No underwire",
            ],
            imageMapCoordinates: ["18.421052631578945%", "49.15980230642503%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["55.42763157894737%", "33.673805601317945%"],
          },
          {
            name: "Straps",
            text: ["Wide", "Strap length adjustable"],
            imageMapCoordinates: ["21.38157894736842%", "10.774299835255341%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of bra (i.e., has hooks, clips or a zipper) that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["65.13157894736842%", "79.96705107084018%"],
          },
        ],
      },
      {
        src: "hybrid-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Wide",
              "Strap length adjustable",
              "Strap orientation can be vertical, cross-over or racer-back",
            ],
            imageMapCoordinates: ["56.74342105263154%", "25.25451559934319%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of bra (i.e., has hooks, clips or a zipper) that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["60.855263157894704%", "79.9343185550082%"],
          },
        ],
      },
      {
        src: "hybrid-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup (only visible from inside the bra)",
              "Ideally, each bra cup has extra breast support at the side and bottom of the cup",
              "Breasts are completely covered (near armpit and top of breast - high round neck), lifted AND compressed against the chest wall (strong elastic fabric)",
              "No underwire",
            ],
            imageMapCoordinates: ["75.98684210526322%", "60.85667215815484%"],
          },
          {
            name: "Straps",
            text: [
              "Wide",
              "Strap length adjustable with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["54.11184210526322%", "14.728171334431616%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of bra (i.e., has hooks, clips or a zipper) that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["57.07236842105269%", "82.76771004942339%"],
          },
        ],
      },
    ],
  },
  CROPTOP_SMALL: {
    name: "Crop Top Sports Bra",
    description:
      "The “Crop Top” style sports bra compresses both breasts as a unit against the chest wall (i.e., it is made of strong elastic fabric). Typically, the length of the straps is NOT adjustable and the band DOES NOT open/close.",
    imageInfo: [
      {
        src: "croptop-small-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Breasts are compressed as a single unit against the chest wall (strong elastic fabric)",
              "Breasts are completely covered (near armpit and top of breast - high round neck)",
              "No underwire",
            ],
            imageMapCoordinates: ["16.776315789473685%", "58.67768595041319%"],
          },
          {
            name: "Straps",
            text: ["Wide, with smooth and non-abrasive inner surface"],
            imageMapCoordinates: ["73.84868421052632%", "17.520661157024755%"],
          },
          {
            name: "Band",
            text: ["Wide, strong elastic fabric that does not curl up"],
            imageMapCoordinates: ["48.848684210526315%", "79.33884297520657%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["62.17105263157895%", "40.826446280991696%"],
          },
        ],
      },
      {
        src: "croptop-small-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Wide, with smooth and non-abrasive inner surface",
              "Variable strap orientation (i.e., vertical or racer-back",
            ],
            imageMapCoordinates: ["62.00657894736843%", "14.778481012658235%"],
          },
          {
            name: "Band",
            text: ["Wide, strong elastic fabric that does not curl up"],
            imageMapCoordinates: ["45.72368421052633%", "82.50000000000001%"],
          },
        ],
      },
      {
        src: "croptop-small-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Breasts are compressed as a single unit against the chest wall (strong elastic fabric)",
              "Breasts are completely covered (near armpit and top of breast - high round neck)",
              "No underwire",
            ],
            imageMapCoordinates: ["81.41447368421048%", "58.11420982735726%"],
          },
          {
            name: "Straps",
            text: ["Wide, with smooth and non-abrasive inner surface"],
            imageMapCoordinates: ["33.881578947368375%", "2.8685258964143605%"],
          },
          {
            name: "Band",
            text: ["Wide, strong elastic fabric that does not curl up"],
            imageMapCoordinates: ["68.4210526315789%", "85.2058432934927%"],
          },
        ],
      },
    ],
  },
  SPORTSBRA_SMALL: {
    name: "Encapsulation Sports Bra",
    description:
      "The “Encapsulation” style sports bra has a separate cup for each breast, the length of the straps is adjustable and the band can open/close at the front or back of the bra (i.e., has hooks, clips or a zipper).",
    imageInfo: [
      {
        src: "sportsbra-small-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup that completely covers the breast (near armpit and top of breast) AND lifts the breast",
              "Ideally, each bra cup has additional breast support at the side and bottom of the cup",
            ],
            imageMapCoordinates: ["74.7107438016529%", "64.99089253187614%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["60.49586776859505%", "44.95446265938072%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["77.35537190082646%", "16.357012750455404%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of a bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["84.95867768595043%", "83.57012750455375%"],
          },
          {
            name: "Front Band",
            text: ["Wide, sits flat against your breastbone"],
            imageMapCoordinates: ["41.32231404958679%", "79.7449908925319%"],
          },
          {
            name: "Underwire",
            text: [
              "If bra has underwire, it MUST sit on your breastbone (at front) and ribs (near armpit) NOT on any breast tissue",
            ],
            imageMapCoordinates: ["14.049586776859524%", "81.20218579234974%"],
          },
        ],
      },
      {
        src: "sportsbra-small-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
              "Strap orientation can be vertical, cross-over or racer-back",
            ],
            imageMapCoordinates: ["77.65089722675366%", "29.550561797752795%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of a bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["59.79965755733217%", "77.93765857194634%"],
          },
        ],
      },
      {
        src: "sportsbra-small-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup that completely covers the breast (near armpit and top of breast) AND lifts the breast",
              "Ideally, each bra cup has additional breast support at the side and bottom of the cup",
            ],
            imageMapCoordinates: ["77.85123966942149%", "67.34375%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["19.00826446280992%", "12.968750000000002%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of a bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["19.00826446280992%", "81.5625%"],
          },
        ],
      },
    ],
  },
  SPORTSBRA_MEDIUM: {
    name: "Encapsulation Sports Bra",
    description:
      "The “Encapsulation” style sports bra has a separate cup for each breast, the length of the straps is adjustable and the band can open/close at the front or back of the bra (i.e., has hooks, clips or a zipper).",
    imageInfo: [
      {
        src: "sportsbra-medium-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup that completely covers the breast (near armpit and top of breast) AND lifts the breast",
              "Ideally, each bra cup has additional breast support at the side and bottom of the cup",
            ],
            imageMapCoordinates: ["18.512396694214875%", "67.8976234003656%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["16.198347107438018%", "13.967093235831785%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of a bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["82.31404958677686%", "85.2650822669104%"],
          },
          {
            name: "Front Band",
            text: ["Wide, sits flat against your breastbone"],
            imageMapCoordinates: ["44.79338842975207%", "74.47897623400364%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["70.74380165289257%", "35.53930530164532%"],
          },
          {
            name: "Underwire",
            text: [
              "If bra has underwire, it MUST sit on your breastbone (at front) and ribs (near armpit) NOT on any breast tissue",
            ],
            imageMapCoordinates: ["19.669421487603316%", "87.09323583180985%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
              "Strap orientation can be vertical, cross-over or racer-back",
            ],
            imageMapCoordinates: ["14.87603305785124%", "27.629870129870117%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of a bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["61.15702479338842%", "81.36363636363635%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup that completely covers the breast (near armpit and top of breast) AND lifts the breast",
              "Ideally, each bra cup has additional breast support at the side and bottom of the cup",
            ],
            imageMapCoordinates: ["80.33057851239668%", "69.84227129337539%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["11.074380165289247%", "33.09148264984228%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of a bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["19.50413223140495%", "79.14826498422714%"],
          },
        ],
      },
    ],
  },
  SPORTSBRA_LARGE: {
    name: "Encapsulation Sports Bra",
    description:
      "The “Encapsulation” style sports bra has a separate cup for each breast, the length of the straps is adjustable and the band can open/close at the front or back of the bra (i.e., has hooks, clips or a zipper).",
    imageInfo: [
      {
        src: "sportsbra-large-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup that completely covers the breast (near armpit and top of breast) AND lifts the breast",
              "Ideally, each bra cup has additional breast support at the side and bottom of the cup",
            ],
            imageMapCoordinates: ["72.39669421487604%", "66.46098003629763%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["72.89256198347107%", "14.192377495462782%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["74.3801652892562%", "88.05807622504535%"],
          },
          {
            name: "Front Band",
            text: ["Wide, sits flat against your breastbone"],
            imageMapCoordinates: ["49.91776315789473%", "78.84476534296037%"],
          },
          {
            name: "Underwire",
            text: [
              "If bra has underwire, it MUST sit on your breastbone (at front) and ribs (near armpit) NOT on any breast tissue",
            ],
            imageMapCoordinates: ["14.380165289256189%", "82.43194192377494%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["27.572016460905353%", "40.867208672086704%"],
          },
        ],
      },
      {
        src: "sportsbra-large-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
              "Strap orientation can be vertical, cross-over or racer-back",
            ],
            imageMapCoordinates: ["76.36363636363632%", "43.83512544802867%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["57.19008264462805%", "79.6774193548387%"],
          },
        ],
      },
      {
        src: "sportsbra-large-left",
        labels: [
          {
            name: "Cups",
            text: [
              "Each breast is encased in a separate cup that completely covers the breast (near armpit and top of breast) AND lifts the breast",
              "Ideally, each bra cup has additional breast support at the side and bottom of the cup",
            ],
            imageMapCoordinates: ["20.165289256198346%", "57.101910828025495%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, with smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["42.29852023877596%", "20.206022187004756%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Opens at back or front of bra, i.e., has hooks, clips or a zipper that have a smooth and non-abrasive inner surface",
            ],
            imageMapCoordinates: ["43.80165289256198%", "84.01273885350321%"],
          },
        ],
      },
    ],
  },
  SBCT: {
    name: "Encapsulation Sports Bra AND Crop Top Sports Bra",
    description:
      "The two-bra combination is an Encapsulation sports bra worn underneath with a Crop top sports bra worn over it to increase the level of breast support. The Encapsulation sports bra lifts and separates the breasts, and the Crop top sports bra increases the level of breast support by compressing the breasts against the chest wall.",
    imageInfo: [
      {
        src: "sbct-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Encapsulation sports bra encases each breast in a separate cup that completely covers AND lifts each breast",
              "Crop top sports bra completely covers the breasts (near armpit and top of breast - high round neck) and compresses them against the chest wall",
            ],
            imageMapCoordinates: ["16.363636363636363%", "68.02867383512546%"],
          },
          {
            name: "Straps",
            text: [
              "Wide, padded",
              "Strap length adjustable, inner surface smooth and non-abrasive",
            ],
            imageMapCoordinates: ["76.52892561983471%", "20.896057347670265%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic that does not curl up",
              "Ideally, the crop top opens at back or front of bra (i.e., has hooks, clips or a zipper) to make it easier to get on/off",
              "Inner surface of hooks, clips or zipper smooth and non-abrasive",
            ],
            imageMapCoordinates: ["71.2396694214876%", "85.23297491039428%"],
          },
          {
            name: "Front band",
            text: [
              "Wide, with smooth and non-abrasive inner surface",
              "Sits flat against your breast bone",
            ],
            imageMapCoordinates: ["50.90909090909091%", "78.06451612903227%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["63.47107438016529%", "39.175627240143385%"],
          },
          {
            name: "Underwire",
            text: [
              "If the encapsulation sports bra has underwire, it MUST sit on your breastbone (at front) and ribs (near armpit), NOT on any breast tissue",
            ],
            imageMapCoordinates: ["26.28099173553719%", "83.44086021505377%"],
          },
        ],
      },
      {
        src: "sbct-back",
        labels: [
          {
            name: "Straps",
            text: [
              "If possible, choose bras that have different strap orientations to spread the load of the breasts across your shoulders (i.e., vertical straps, racer back, cross-over)",
              "Wide, padded",
              "Strap length adjustable, inner surface smooth and non-abrasive",
            ],
            imageMapCoordinates: ["48.760330578512395%", "35.68965517241377%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic that does not curl up",
              "Ideally, the crop top opens at back or front of bra (i.e., has hooks, clips or a zipper) to make it easier to get on/off",
              "Inner surface of hooks, clips or zipper smooth and non-abrasive",
            ],
            imageMapCoordinates: ["64.29752066115702%", "82.5862068965517%"],
          },
        ],
      },
      {
        src: "sbct-left",
        labels: [
          {
            name: "Cups",
            text: [
              "Encapsulation sports bra encases each breast in a separate cup that completely covers AND lifts each breast",
              "Crop top sports bra completely covers the breasts (near armpit and top of breast - high round neck) and compresses them against the chest wall",
            ],
            imageMapCoordinates: ["16.363636363636363%", "61.51857835218094%"],
          },
          {
            name: "Straps",
            text: [
              "If possible, choose bras that have different strap orientations to spread the load of the breasts across your shoulders (i.e., vertical straps, racer back, cross-over)",
              "Wide, padded",
              "Strap length adjustable, inner surface smooth and non-abrasive",
            ],
            imageMapCoordinates: ["31.5702479338843%", "25.008077544426495%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic that does not curl up",
              "Ideally, the crop top opens at back or front of bra (i.e., has hooks, clips or a zipper) to make it easier to get on/off",
              "Inner surface of hooks, clips or zipper smooth and non-abrasive",
            ],
            imageMapCoordinates: ["42.4793388429752%", "83.97415185783521%"],
          },
        ],
      },
    ],
  },
};

export const correctFitBraDetails = {
  HYBRID: {
    imageInfo: [
      {
        src: "hybrid-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast), lifted AND compressed against your chest wall",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["18.421052631578945%", "49.15980230642503%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["21.38157894736842%", "10.774299835255341%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["65.13157894736842%", "79.96705107084018%"],
          },
          {
            name: "Front band",
            text: ["Sits flat against your breast bone, no gaping"],
            imageMapCoordinates: ["46.776859504132226%", "76.12582781456953%"],
          },
        ],
      },
      {
        src: "hybrid-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["56.74342105263154%", "25.25451559934319%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
              "Ideally, correctly fit a new bra on the loosest clip/hook because the band will stretch as it gets older",
            ],
            imageMapCoordinates: ["60.855263157894704%", "79.9343185550082%"],
          },
        ],
      },
      {
        src: "hybrid-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast), lifted AND compressed against chest wall",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["75.98684210526322%", "60.85667215815484%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["54.11184210526322%", "14.728171334431616%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["57.07236842105269%", "82.76771004942339%"],
          },
        ],
      },
    ],
  },
  CROPTOP_SMALL: {
    imageInfo: [
      {
        src: "croptop-small-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast completely covered (near armpit and top of breast) and compressed against chest wall",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["16.776315789473685%", "58.67768595041319%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["73.84868421052632%", "17.520661157024755%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["48.848684210526315%", "79.33884297520657%"],
          },
        ],
      },
      {
        src: "croptop-small-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["62.00657894736843%", "14.778481012658235%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["45.72368421052633%", "82.50000000000001%"],
          },
        ],
      },
      {
        src: "croptop-small-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast) and compressed against chest wall",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["81.41447368421048%", "58.11420982735726%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["33.881578947368375%", "2.8685258964143605%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["68.4210526315789%", "85.2058432934927%"],
          },
        ],
      },
    ],
  },
  CROPTOP_LARGE: {
    imageInfo: [
      {
        src: "croptop-large-front",
        labels: [
          {
            name: "Front",
            text: [
              "Breasts compressed as a unit against the chest wall (strong elastic fabric)",
              "Breasts are completely covered (near armpit and top of breast - high round neck)",
              "No underwire",
            ],
            imageMapCoordinates: ["14.75826972010178%", "71.41242937853107%"],
          },
          {
            name: "Straps",
            text: ["Wide, with smooth and non-abrasive inner surface"],
            imageMapCoordinates: ["20.610687022900763%", "15.197740112994355%"],
          },
          {
            name: "Band",
            text: [
              "Wide, strong elastic fabric that does not curl up",
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["75.06361323155215%", "88.36158192090396%"],
          },
          {
            name: "Material",
            text: ["Soft, smooth fabric", "Seams smooth, non-abrasive"],
            imageMapCoordinates: ["55.72519083969464%", "46.27118644067797%"],
          },
        ],
      },
      {
        src: "croptop-large-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["43.51145038167939%", "30.215053763440864%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["34.096692111959285%", "85.86021505376344%"],
          },
        ],
      },
      {
        src: "croptop-large-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast completely covered (near armpit and top of breast) and compressed against chest wall",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["82.1882951653944%", "67.12041884816753%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "Not digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["51.65394402035622%", "17.120418848167535%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["13.231552162849866%", "82.56544502617801%"],
          },
        ],
      },
    ],
  },
  SPORTSBRA_SMALL: {
    imageInfo: [
      {
        src: "sportsbra-small-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast) AND lifted",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["74.7107438016529%", "64.99089253187614%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["77.35537190082646%", "16.357012750455404%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["84.95867768595043%", "83.57012750455375%"],
          },
          {
            name: "Front band",
            text: ["Sits flat against your breast bone, no gaping"],
            imageMapCoordinates: ["41.32231404958679%", "79.7449908925319%"],
          },
        ],
      },
      {
        src: "sportsbra-small-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["77.65089722675366%", "29.550561797752795%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
              "Ideally, correctly fit a new bra on the loosest clip/hook because the band will stretch as it gets older",
            ],
            imageMapCoordinates: ["59.79965755733217%", "77.93765857194634%"],
          },
        ],
      },
      {
        src: "sportsbra-small-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast) AND lifted",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["77.85123966942149%", "67.34375%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["19.00826446280992%", "12.968750000000002%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["19.00826446280992%", "81.5625%"],
          },
        ],
      },
    ],
  },
  SPORTSBRA_MEDIUM: {
    imageInfo: [
      {
        src: "sportsbra-medium-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast) AND lifted",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["18.512396694214875%", "67.8976234003656%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["16.198347107438018%", "13.967093235831785%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["82.31404958677686%", "85.2650822669104%"],
          },
          {
            name: "Front band",
            text: ["Sits flat against your breast bone, no gaping"],
            imageMapCoordinates: ["44.79338842975207%", "74.47897623400364%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["14.87603305785124%", "27.629870129870117%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
              "Ideally, correctly fit a new bra on the loosest clip/hook because the band will stretch as it gets older",
            ],
            imageMapCoordinates: ["61.15702479338842%", "81.36363636363635%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-right",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast) AND lifted",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["80.33057851239668%", "69.84227129337539%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["11.074380165289247%", "33.09148264984228%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["19.50413223140495%", "79.14826498422714%"],
          },
        ],
      },
    ],
  },
  SPORTSBRA_LARGE: {
    imageInfo: [
      {
        src: "sportsbra-large-front",
        labels: [
          {
            name: "Cups",
            text: [
              "Whole breast completely covered (near armpit and top of breast) AND lifted",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["72.39669421487604%", "66.46098003629763%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["72.89256198347107%", "14.192377495462782%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["74.3801652892562%", "88.05807622504535%"],
          },
          {
            name: "Front band",
            text: ["Sits flat against your breast bone, no gaping"],
            imageMapCoordinates: ["47.0716265213391%", "74.95711835334477%"],
          },
        ],
      },
      {
        src: "sportsbra-large-back",
        labels: [
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["76.36363636363632%", "43.83512544802867%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
              "Ideally, correctly fit a new bra on the loosest clip/hook because the band will stretch as it gets older",
            ],
            imageMapCoordinates: ["57.19008264462805%", "79.6774193548387%"],
          },
        ],
      },
      {
        src: "sportsbra-large-left",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast completely covered (near armpit and top of breast) AND lifted",
              "No breast tissue bulging above top of bra or near armpit OR wrinkles or gaps",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["20.165289256198346%", "57.101910828025495%"],
          },
          {
            name: "Straps",
            text: [
              "Firm but comfortable",
              "No digging in OR sliding off your shoulders",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["42.05761316872429%", "20.475435816164808%"],
          },
          {
            name: "Band",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["43.80165289256198%", "84.01273885350321%"],
          },
        ],
      },
    ],
  },
  SBCT: {
    imageInfo: [
      {
        src: "sbct-front",
        labels: [
          {
            name: "Cups of both bras",
            text: [
              "Whole breast completely covered (near armpit and top of breast), lifted AND compressed against chest wall",
              "No breast tissue bulging above top of bra or near armpit",
              "No wrinkles or gaps in fabric",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["16.363636363636363%", "68.02867383512546%"],
          },
          {
            name: "Straps of both bras",
            text: [
              "Firm but comfortable",
              "Not digging in OR sliding off your shoulders",
              "Inner surface of straps feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["76.52892561983471%", "20.896057347670265%"],
          },
          {
            name: "Band of both bras",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Inner surface of band feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["71.2396694214876%", "85.23297491039428%"],
          },
          {
            name: "Front band",
            text: ["Sits flat against your breast bone, no gaping"],
            imageMapCoordinates: ["50.90909090909091%", "78.06451612903227%"],
          },
          {
            name: "Underwire",
            text: [
              "If the encapsulation sports bra has underwire, it MUST sit on your breastbone (at front) and ribs (near armpit) NOT on any breast tissue",
            ],
            imageMapCoordinates: ["26.28099173553719%", "83.44086021505377%"],
          },
        ],
      },
      {
        src: "sbct-back",
        labels: [
          {
            name: "Straps of both bras",
            text: [
              "Firm but comfortable",
              "Not digging in OR sliding off your shoulders",
              "Inner surface of straps feels smooth and non-abrasive",
              "Typically, crop tops do not have adjustable strap length",
              "Adjustable straps are preferable to help with bra fit",
            ],
            imageMapCoordinates: ["48.760330578512395%", "35.68965517241377%"],
          },
          {
            name: "Band of both bras",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface feels smooth and non-abrasive",
              "Ideally, correctly fit a new bra on the loosest clip/hook because bras decrease elasticity as they age",
              "Typically, crop tops do not have hooks/clips (ability to open/close)",
              "Crops tops that can open/close are easier to put on and take off",
            ],
            imageMapCoordinates: ["64.29752066115702%", "82.5862068965517%"],
          },
        ],
      },
      {
        src: "sbct-left",
        labels: [
          {
            name: "Cups of both bras",
            text: [
              "Whole breast completely covered (near armpit and top of breast), lifted AND compressed against chest wall",
              "No breast tissue bulging above top of bra or near armpit",
              "No wrinkles or gaps in fabric",
              "Material feels soft and smooth",
              "Seams are smooth and non-abrasive",
            ],
            imageMapCoordinates: ["16.363636363636363%", "61.51857835218094%"],
          },
          {
            name: "Straps of both bras",
            text: [
              "Firm but comfortable",
              "Not digging in OR sliding off your shoulders",
              "Inner surface of straps feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["31.5702479338843%", "25.008077544426495%"],
          },
          {
            name: "Band of both bras",
            text: [
              "Firm but comfortable",
              "Does not ride up when you raise your arms",
              "Level at front and back of bra",
              "Inner surface of band feels smooth and non-abrasive",
            ],
            imageMapCoordinates: ["42.4793388429752%", "83.97415185783521%"],
          },
          {
            name: "Underwire",
            text: [
              "If the Encapsulation sports bra has underwire, it MUST sit on your breastbone (at front) and ribs (near armpit) NOT on any breast tissue",
            ],
            imageMapCoordinates: ["22.8099173553719%", "85.41518578352182%"],
          },
        ],
      },
    ],
  },
};

export const tooBigBraDetails = {
  SB_SMALL: {
    imageInfo: [
      {
        src: "sportsbra-small-front-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["80.66115702479338%", "15.312499999999998%"],
          },
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging - NOT lifted or compressed by bra cup",
            ],
            imageMapCoordinates: ["81.48760330578513%", "66.00694444444444%"],
          },
          {
            name: "Underwire",
            text: [
              "Not staying in place - moves around and sits on breast tissue instead of your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["24.958677685950413%", "81.97916666666667%"],
          },
          {
            name: "Front Band",
            text: ["Sits away from your breastbone, creating a gap"],
            imageMapCoordinates: ["48.264462809917354%", "72.08333333333333%"],
          },
        ],
      },
      {
        src: "sportsbra-small-back-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["79.17355371900825%", "26.0390763765542%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, even when on the tightest fasteners",
              "Band rides up when you raise your arms",
              "Band at back sits higher than the band at the front (i.e. not level)",
            ],
            imageMapCoordinates: ["57.85123966942148%", "72.04262877442275%"],
          },
        ],
      },
      {
        src: "sportsbra-small-left-toobig",
        labels: [
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging - NOT lifted or compressed by bra cup",
            ],
            imageMapCoordinates: ["17.68595041322314%", "66.81528662420384%"],
          },
          {
            name: "Underwire",
            text: [
              "Not staying in place - moves around and sits on breast tissue instead of your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["20.826446280991735%", "85.7643312101911%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, even when on tightest fasteners",
              "Band rides up when you raise your arms",
              "Band at the back sits higher than the band at the front, (i.e., not level)",
            ],
            imageMapCoordinates: ["80.82644628099173%", "73.50318471337582%"],
          },
        ],
      },
    ],
  },
  SB_MEDIUM: {
    imageInfo: [
      {
        src: "sportsbra-medium-front-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["14.380165289256198%", "17.647058823529427%"],
          },
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging - NOT lifted or compressed by bra cup",
            ],
            imageMapCoordinates: ["80.33057851239668%", "70.0840336134454%"],
          },
          {
            name: "Underwire",
            text: [
              "Not staying in place - moves around and sits on breast tissue instead of your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["72.23140495867769%", "86.72268907563029%"],
          },
          {
            name: "Front Band",
            text: ["Sits away from your breastbone, creating a gap"],
            imageMapCoordinates: ["44.95867768595041%", "73.27731092436979%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-back-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["13.848486025471333%", "26.323987538940806%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, even when on the tightest fasteners",
              "Band rides up when you raise your arms",
              "Band at the back sits higher than the band at the front (i.e., not level)",
            ],
            imageMapCoordinates: ["57.980717430430005%", "80.8411214953271%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-right-toobig",
        labels: [
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging - NOT lifted or compressed by bra cup",
            ],
            imageMapCoordinates: ["67.60330578512396%", "62.15686274509803%"],
          },
          {
            name: "Underwire",
            text: [
              "Not staying in place - moves around and sits on breast tissue instead of your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["57.85123966942148%", "83.88888888888887%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, even when on the tightest fasteners",
              "Band rides up when you raise your arms",
              "Band at the back sits higher than the band at the front (i.e., not level)",
            ],
            imageMapCoordinates: ["13.553719008264453%", "77.02614379084966%"],
          },
        ],
      },
    ],
  },
  SB_LARGE: {
    imageInfo: [
      {
        src: "sportsbra-large-front-toobig",
        labels: [
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging - NOT lifted or compressed by bra cup",
            ],
            imageMapCoordinates: ["19.468320736215134%", "64.83704974271012%"],
          },
          {
            name: "Underwire",
            text: [
              "Not staying in place – moves around and sits on breast tissue instead of your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["68.72451908332258%", "82.84734133790738%"],
          },
          {
            name: "Front Band",
            text: ["Sits away from your breastbone, creating a gap"],
            imageMapCoordinates: ["47.0716265213391%", "74.95711835334477%"],
          },
        ],
      },
      {
        src: "sportsbra-large-back-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["71.53443643869447%", "22.261484098939928%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, even when on the tightest fasteners",
              "Band rides up when you raise your arms",
              "Band at the back sits higher than the band at the front (i.e., not level)",
            ],
            imageMapCoordinates: ["55.17080007505811%", "63.957597173144876%"],
          },
        ],
      },
      {
        src: "sportsbra-large-left-toobig",
        labels: [
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging - NOT lifted or compressed by bra cup",
            ],
            imageMapCoordinates: ["22.774105860182075%", "71.7219589257504%"],
          },
          {
            name: "Underwire",
            text: [
              "Not staying in place – moves around and sits on breast tissue instead of your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["38.807163711421744%", "81.04265402843602%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, even when on the tightest fasteners",
              "Band rides up back when you raise your arms",
              "Band at the back sits higher than the band at the front (i.e., not level",
            ],
            imageMapCoordinates: ["85.41873395935563%", "56.39810426540285%"],
          },
        ],
      },
    ],
  },
  CT_SMALL_MEDIUM: {
    imageInfo: [
      {
        src: "croptop-small-front-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["72.03030420728952%", "18.070175438596493%"],
          },
          {
            name: "Cups",
            text: [
              "Wrinkles and gaps",
              "Breasts sagging – NOT compressed against chest wall",
            ],
            imageMapCoordinates: ["72.52617197588455%", "61.05263157894737%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, rides up when you raise your arms",
              "Wrinkles in fabric",
            ],
            imageMapCoordinates: ["24.92286619076059%", "81.22807017543859%"],
          },
        ],
      },
      {
        src: "croptop-small-back-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["35.66666784365315%", "12.622950819672132%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, rides up when you raise your arms",
              "Wrinkles in fabric",
            ],
            imageMapCoordinates: ["61.121213298198604%", "81.14754098360656%"],
          },
        ],
      },
      {
        src: "croptop-small-right-toobig",
        labels: [
          {
            name: "Cups",
            text: [
              "Wrinkles or gaps",
              "Breasts sagging – NOT compressed against chest wall",
            ],
            imageMapCoordinates: ["76.49311412464489%", "61.02841677943166%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, rides up when you raise your arms",
              "Wrinkles in fabric",
            ],
            imageMapCoordinates: ["62.44352734778538%", "81.86738836265224%"],
          },
        ],
      },
    ],
  },
  CT_LARGE: {
    imageInfo: [
      {
        src: "croptop-large-front-toobig",
        labels: [
          {
            name: "Cups",
            text: [
              "Wrinkles or gaps",
              "Breasts sagging – NOT compressed against chest wall",
            ],
            imageMapCoordinates: ["75.17080007505811%", "64.59412780656304%"],
          },
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["73.51790751307465%", "19.516407599309154%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, rides up when you raise your arms",
              "Wrinkles in fabric",
            ],
            imageMapCoordinates: ["39.44020356234097%", "88.08510638297871%"],
          },
        ],
      },
      {
        src: "croptop-large-back-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, could slide off your shoulders"],
            imageMapCoordinates: ["59.127242569044405%", "29.19020715630885%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, rides up when you raise your arms",
              "Wrinkles in fabric",
              "Typically, crop tops do not have hooks/clips (ability to open/close)",
              "Crops tops that can open/close are easier to put on and take off",
            ],
            imageMapCoordinates: ["43.25947397400308%", "71.44642130544844%"],
          },
        ],
      },
      {
        src: "croptop-large-right-toobig",
        labels: [
          {
            name: "Straps",
            text: ["Too loose, can slide off your shoulders"],
            imageMapCoordinates: ["48.85496183206108%", "16.7391304347826%"],
          },
          {
            name: "Cups",
            text: [
              "Wrinkles or gaps",
              "Breasts sagging – NOT compressed against chest wall",
            ],
            imageMapCoordinates: ["78.6418744552234%", "61.19929453262787%"],
          },
          {
            name: "Band",
            text: [
              "Too loose, rides up when you raise your arms",
              "Wrinkles in fabric",
            ],
            imageMapCoordinates: ["12.19559346348786%", "73.36860670194002%"],
          },
        ],
      },
    ],
  },
};

export const tooSmallBraDetails = {
  SB_SMALL_MEDIUM: {
    imageInfo: [
      {
        src: "sportsbra-medium-front-toosmall",
        labels: [
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["76.49311412464489%", "20.616883116883113%"],
          },
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered (near armpit and top of breast)",
            ],
            imageMapCoordinates: ["59.303031480016784%", "55.35714285714286%"],
          },
          {
            name: "Underwire",
            text: [
              "Sitting on breast tissue rather than against your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["19.303031480016788%", "83.27922077922078%"],
          },
          {
            name: "Front Band",
            text: ["Sits away from your breastbone, creating a gap"],
            imageMapCoordinates: ["43.76584139737216%", "75.81168831168831%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["82.93939511638042%", "83.44155844155844%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-back-toosmall",
        labels: [
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["75.33608933125646%", "8.24915824915825%"],
          },
          {
            name: "Band",
            text: [
              "Feels too tight, uncomfortable and cannot be loosened because no more hooks/fasteners to use",
            ],
            imageMapCoordinates: ["35.83195709985149%", "81.81818181818183%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-right-toosmall",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered (near armpit and top of breast)",
            ],
            imageMapCoordinates: ["80.79063478580191%", "55.714285714285715%"],
          },
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["28.393940570925878%", "6.507936507936509%"],
          },
          {
            name: "Underwire",
            text: [
              "Sitting on breast tissue rather than against your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["63.93113065357051%", "81.74603174603175%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["41.947659579190336%", "85.71428571428571%"],
          },
        ],
      },
    ],
  },
  SB_LARGE: {
    imageInfo: [
      {
        src: "sportsbra-large-front-toosmall",
        labels: [
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["23.104684372578777%", "9.507042253521126%"],
          },
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered (near armpit and top of breast)",
            ],
            imageMapCoordinates: ["9.716254620512654%", "51.58450704225353%"],
          },
          {
            name: "Underwire",
            text: [
              "Sitting on breast tissue rather than against your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["34.01377528166968%", "81.33802816901408%"],
          },
          {
            name: "Front Band",
            text: ["Sits away from your breastbone, creating a gap"],
            imageMapCoordinates: ["51.69972569489282%", "78.16901408450704%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["80.62534552960356%", "87.67605633802818%"],
          },
        ],
      },
      {
        src: "sportsbra-large-back-toosmall",
        labels: [
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["74.84022156266141%", "6.118881118881119%"],
          },
          {
            name: "Band",
            text: [
              "Feels too tight, uncomfortable and cannot be loosened because no more hooks/fasteners to use",
            ],
            imageMapCoordinates: ["60.29476701720687%", "83.74125874125873%"],
          },
        ],
      },
      {
        src: "sportsbra-large-right-toosmall",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered (near armpit and top of breast)",
            ],
            imageMapCoordinates: ["67.89807280233083%", "49.41569282136895%"],
          },
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["12.195593463487853%", "16.026711185308848%"],
          },
          {
            name: "Underwire",
            text: [
              "Sitting on breast tissue rather than against your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["60.955924042000255%", "75.95993322203672%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["14.509643050264721%", "78.13021702838063%"],
          },
        ],
      },
    ],
  },
  CT_SMALL_MEDIUM: {
    imageInfo: [
      {
        src: "sportsbra-medium-front-toosmall",
        labels: [
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["76.49311412464489%", "20.616883116883113%"],
          },
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered (near armpit and top of breast)",
            ],
            imageMapCoordinates: ["59.303031480016784%", "55.35714285714286%"],
          },
          {
            name: "Underwire",
            text: [
              "Sitting on breast tissue rather than against your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["19.303031480016788%", "83.27922077922078%"],
          },
          {
            name: "Front Band",
            text: ["Sits away from your breastbone, creating a gap"],
            imageMapCoordinates: ["43.76584139737216%", "75.81168831168831%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["82.93939511638042%", "83.44155844155844%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-back-toosmall",
        labels: [
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["75.33608933125646%", "8.24915824915825%"],
          },
          {
            name: "Band",
            text: [
              "Feels too tight, uncomfortable and cannot be loosened because no more hooks/fasteners to use",
            ],
            imageMapCoordinates: ["35.83195709985149%", "81.81818181818183%"],
          },
        ],
      },
      {
        src: "sportsbra-medium-right-toosmall",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered (near armpit and top of breast)",
            ],
            imageMapCoordinates: ["80.79063478580191%", "55.714285714285715%"],
          },
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["28.393940570925878%", "6.507936507936509%"],
          },
          {
            name: "Underwire",
            text: [
              "Sitting on breast tissue rather than against your breastbone (at front) and ribs (near armpit)",
            ],
            imageMapCoordinates: ["63.93113065357051%", "81.74603174603175%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["41.947659579190336%", "85.71428571428571%"],
          },
        ],
      },
    ],
  },
  CT_LARGE: {
    imageInfo: [
      {
        src: "croptop-large-front-toosmall",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered near armpit and top of breast",
            ],
            imageMapCoordinates: ["5.5840232155539775%", "46.913580246913575%"],
          },
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["72.6914612320829%", "8.289241622574956%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["67.0716265213391%", "83.9506172839506%"],
          },
        ],
      },
      {
        src: "croptop-large-back-toosmall",
        labels: [
          {
            name: "Straps",
            text: [
              "Uncomfortable, digging into your shoulders",
              "Typically, crop tops do not have adjustable strap length",
              "Adjustable straps are preferable to help with bra fit",
            ],
            imageMapCoordinates: ["72.85675048828125%", "9.090909090909092%"],
          },
          {
            name: "Band",
            text: [
              "Feels too tight, uncomfortable and cannot be loosened",
              "Typically, crop tops do not have hooks/clips (ability to open/close)",
              "Crops tops that can open/close are easier to put on and take off",
            ],
            imageMapCoordinates: ["50.04683313290935%", "69.34046345811052%"],
          },
        ],
      },
      {
        src: "croptop-large-right-toosmall",
        labels: [
          {
            name: "Cups",
            text: [
              "Breast tissue bulging above top of cups or near armpit",
              "Breasts NOT completely covered near armpit and top of breast",
            ],
            imageMapCoordinates: ["72.89256198347107%", "32.12598425196852%"],
          },
          {
            name: "Band",
            text: ["Feels too tight, uncomfortable"],
            imageMapCoordinates: ["76.49311412464489%", "83.46456692913385%"],
          },
          {
            name: "Straps",
            text: ["Uncomfortable, digging into your shoulders"],
            imageMapCoordinates: ["12.19559346348786%", "17.00787401574803%"],
          },
        ],
      },
    ],
  },
};
