export const EXTERNALSITES = {
  AIS: "https://www.ais.gov.au/",
  BRA: "https://www.uow.edu.au/science-medicine-health/research/bra/",
  RESOURCES:
    "https://www.uow.edu.au/science-medicine-health/research/bra/breast-support-resources",
  EVIDENCE:
    "https://www.uow.edu.au/science-medicine-health/research/brl/bra/research-publications/",
  UOW: "https://www.uow.edu.au/",
  FAQ: "https://www.uow.edu.au/science-medicine-health/research/bra/faqs"
};
