import React from "react";
import Container from "../components/container";
import ChooseYourBra from "../components/SportsBra/chooseYourBra";
import FittingYourBra from "../components/SportsBra/fittingYourBra";
import PuttingOnYourBra from "../components/SportsBra/puttingOnYourBra";
import YourRecommendedBra from "../components/SportsBra/yourRecommendedBra";
import CheckBraSupport from "../components/SportsBra/checkBraSupport";
import { useWizardContextState } from "../context/wizardContext";
import WizardStep from "../components/SportsBra/WizardComponents/wizardStep";
import WizardContainer from "../components/SportsBra/WizardComponents/wizardContainer";
import styles from "./sportsBra.module.css";
import { processBraStats } from "../services/sportsBraProcessingService";

const SportsBra = () => {
  const wizardContext = useWizardContextState();

  const { cup, age, intensity, band, currentStep } = wizardContext;

  React.useEffect(() => {
    sessionStorage.setItem("age", JSON.stringify(age));
    sessionStorage.setItem("band", JSON.stringify(band));
    sessionStorage.setItem("intensity", JSON.stringify(intensity));
    sessionStorage.setItem("cup", JSON.stringify(cup));
    sessionStorage.setItem("currentStep", JSON.stringify(currentStep));
  }, [cup, age, intensity, band, currentStep]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const HeaderNav = () => {
    return (
      <>
        <div className={styles.wizardStepsContainer}>
          <WizardStep step={0} stepToDisplay={"1"}>
            Choose your bra
          </WizardStep>
          <WizardStep step={2}>Put on your bra</WizardStep>
          <WizardStep step={3}>Fit your bra</WizardStep>
          <WizardStep step={4}>Check bra support</WizardStep>
        </div>
      </>
    );
  };

  const FooterNav = () => {
    return (
      <>
        {currentStep === 0 && (
          <div className={styles.buttonGroup}>
            <button
              className={styles.actionButton}
              onClick={() => wizardContext.setCurrentStep(1)}
            >
              Find your bra
            </button>
          </div>
        )}
        {currentStep === 1 && (
          <div className={styles.buttonGroup}>
            <button
              className={styles.secondaryButton}
              onClick={() => wizardContext.setCurrentStep(0)}
            >
              Back
            </button>
            <button
              className={styles.actionButton}
              onClick={() => wizardContext.setCurrentStep(2)}
            >
              Put on your bra
            </button>
          </div>
        )}
        {currentStep === 2 && (
          <div className={styles.buttonGroup}>
            <button
              className={styles.secondaryButton}
              onClick={() => wizardContext.setCurrentStep(1)}
            >
              Back
            </button>
            <button
              className={styles.actionButton}
              onClick={() => wizardContext.setCurrentStep(3)}
            >
              Fit your bra
            </button>
          </div>
        )}
        {currentStep === 3 && (
          <div className={styles.buttonGroup}>
            <button
              className={styles.secondaryButton}
              onClick={() => wizardContext.setCurrentStep(2)}
            >
              Back
            </button>
            <button
              className={styles.actionButton}
              onClick={() => wizardContext.setCurrentStep(4)}
            >
              Check your bra support
            </button>
          </div>
        )}
        {currentStep === 4 && (
          <div className={styles.buttonGroup}>
            <button
              className={styles.secondaryButton}
              onClick={() => wizardContext.setCurrentStep(3)}
            >
              Back
            </button>
            <button
              className={styles.actionButton}
              onClick={() => wizardContext.setCurrentStep(0)}
            >
              Check another bra
            </button>
          </div>
        )}
      </>
    );
  };

  const recommendation = processBraStats(cup, age, intensity, band);

  return (
    <Container>
      <HeaderNav />
      <div>
        {currentStep === 0 && (
          <WizardContainer wizardHeading={"Tell us about your usual bra size"}>
            <ChooseYourBra />
          </WizardContainer>
        )}

        {currentStep === 1 && (
          <WizardContainer wizardHeading={"We recommend you wear"}>
            <YourRecommendedBra breastSupportRecommended={recommendation} />
          </WizardContainer>
        )}

        {currentStep === 2 && (
          <WizardContainer
            wizardHeading={"How to put on your sports bra"}
            wizardSubheading={
              "When putting on a new bra, follow the steps below. Each step is shown in the video."
            }
          >
            <PuttingOnYourBra />
          </WizardContainer>
        )}

        {currentStep === 3 && (
          <WizardContainer
            wizardHeading={"4 steps to correct bra fit"}
            wizardSubheading={
              "Start with what you think is your correct bra size, put the bra on and then follow the fitting tips below."
            }
          >
            <FittingYourBra breastSupportRecommended={recommendation} />
          </WizardContainer>
        )}

        {currentStep === 4 && (
          <WizardContainer
            wizardHeading={"Is your bra supportive enough?"}
            wizardSubheading={
              <span>
                A supportive sports bra will reduce and slow down the movement
                of your breasts, so it is comfortable to exercise. Preventing
                your breasts “slapping” down against your torso while you are
                active is most important.{" "}
                <strong>
                  How much your breasts move and, in turn, the bra you need
                  might vary during different activities.{" "}
                </strong> 
                No bra, however, should completely stop your breasts moving
                because to achieve absolutely no motion would require an
                extremely rigid and/or tight garment that is likely to be too
                uncomfortable to wear!
              </span>
            }
          >
            <CheckBraSupport breastSupportRecommended={recommendation} />
          </WizardContainer>
        )}
      </div>

      <FooterNav />
    </Container>
  );
};

export default SportsBra;
