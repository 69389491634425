import React from "react";
import styles from "./videoCaption.module.css";

export const VideoCaption = (props) => {
  const { cup, tick } = props;

  if (cup < "D") {
    if (tick < 6) {
      return <div className={styles.videoCaption}>Loosen straps</div>;
    } else if (tick < 14) {
      return (
        <div className={styles.videoCaption}>
          Do up band on loosest fastener
        </div>
      );
    } else if (tick < 24) {
      return (
        <div className={styles.videoCaption}>
          Stabilise band, pull all of breast into cup
        </div>
      );
    } else if (tick < 35) {
      return (
        <div className={styles.videoCaption}>
          Check straps, tighten both if necessary
        </div>
      );
    } else if (tick < 40) {
      return (
        <div className={styles.videoCaption}>
          Stabilise band, pull all of breast into cup, again
        </div>
      );
    } else if (tick < 43) {
      return <div className={styles.videoCaption}>Sweep</div>;
    } else {
      return <></>;
    }
  } else {
    if (tick < 4) {
      return <div className={styles.videoCaption}>Loosen straps</div>;
    } else if (tick < 8) {
      return (
        <div className={styles.videoCaption}>
          Do up band on loosest fastener
        </div>
      );
    } else if (tick < 18) {
      return (
        <div className={styles.videoCaption}>
          Stabilise band, pull all of breast into cup
        </div>
      );
    } else if (tick < 28) {
      return (
        <div className={styles.videoCaption}>
          Check straps, tighten both if necessary
        </div>
      );
    } else if (tick < 36) {
      return (
        <div className={styles.videoCaption}>
          Stabilise band, pull all of breast into cup, again
        </div>
      );
    } else if (tick < 38) {
      return <div className={styles.videoCaption}>Sweep</div>;
    } else {
      return <></>;
    }
  }
};

export default VideoCaption;
