import React from "react"
import styles from "./wizardContainer.module.css"

const WizardContainer = props => {
  return (
    <div className={styles.container}>
          <h1 className={styles.wizardHeading}>{props.wizardHeading}</h1>
          {props.wizardSubheading && <div className={styles.text}>{props.wizardSubheading}</div>}
        {props.children}
    </div>
  )
}

export default WizardContainer;