import React from "react";
import ModalButton from "./modalButton";
import ImageWithLabels from "./WizardComponents/imageWithLabels";
import styles from "./braFittingTips.module.css";

export const BraFittingTips = (props) => {
  const { recommended, tips } = props;

  const modalProps = [
    {
      buttonLabel: "Correct Fit",
      modalTitle: "Correct Fit",
      className: "correct",
    },
    {
      buttonLabel: "Too Big",
      modalTitle: "Too Big",
      className: "too-big",
    },
    {
      buttonLabel: "Too Small",
      modalTitle: "Too Small",
      className: "too-small",
    },
  ];

  return (
    <div className={styles.braFittingTipsContainer}>
      <h2 className={styles.title}>Bra fitting tips</h2>
      <div className={styles.modalContainer}>
        {modalProps.map((props) => {
          return (
            <ModalButton
              buttonLabel={props.buttonLabel}
              modalTitle={props.modalTitle}
              className={props.className}
            >
              {props.modalTitle.includes("Small") && (
                <div>
                  <ImageWithLabels
                    braToDisplay={tips.tooSmall[0]}
                    titlePrefix={recommended[0].name}
                  />
                  {tips.tooSmall[1]?.name && (
                    <ImageWithLabels
                      braToDisplay={tips.tooSmall[1]}
                      titlePrefix={recommended[1]?.name}
                    />
                  )}

                  {tips.tooSmall[2]?.name && (
                    <ImageWithLabels
                      braToDisplay={tips.tooSmall[2]}
                      titlePrefix={recommended[1]?.name}
                    />
                  )}
                </div>
              )}
              {props.modalTitle.includes("Big") && (
                <div>
                  <ImageWithLabels
                    braToDisplay={tips.tooBig[0]}
                    titlePrefix={recommended[0].name}
                  />
                  {tips.tooBig[1]?.name && (
                    <ImageWithLabels
                      braToDisplay={tips.tooBig[1]}
                      titlePrefix={recommended[1]?.name}
                    />
                  )}

                  {tips.tooBig[2]?.name && (
                    <ImageWithLabels
                      braToDisplay={tips.tooBig[2]}
                      titlePrefix={recommended[1]?.name}
                    />
                  )}
                </div>
              )}
              {props.modalTitle.includes("Fit") && (
                <div>
                  <ImageWithLabels
                    braToDisplay={tips.correctFit[0]}
                    titlePrefix={recommended[0].name}
                  />
                  {tips.correctFit[1]?.name && (
                    <ImageWithLabels
                      braToDisplay={tips.correctFit[1]}
                      titlePrefix={recommended[1]?.name}
                    />
                  )}

                  {tips.correctFit[2]?.name && (
                    <ImageWithLabels
                      braToDisplay={tips.correctFit[2]}
                      titlePrefix={recommended[2]?.name}
                    />
                  )}
                </div>
              )}
            </ModalButton>
          );
        })}
      </div>
    </div>
  );
};

export default BraFittingTips;
