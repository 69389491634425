import React, { useState } from "react";

export const WizardContext = React.createContext();

export const WizardProvider = ({ children }) => {
  const getSessionStorageOrDefault = (key, defaultValue) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  };

  const [currentStep, setCurrentStep] = useState(
    getSessionStorageOrDefault("currentStep", 0)
  );
  const [age, setAge] = useState(getSessionStorageOrDefault("age", "30 to 45"));
  const [cup, setCup] = useState(getSessionStorageOrDefault("cup", "C"));
  const [band, setBand] = useState(getSessionStorageOrDefault("band", 12));
  const [intensity, setIntensity] = useState(
    getSessionStorageOrDefault("intensity", "Moderate")
  );

  const wizardState = {
    currentStep,
    age,
    cup,
    intensity,
    band,
    setCurrentStep,
    setAge,
    setCup,
    setIntensity,
    setBand,
  };

  return (
    <WizardContext.Provider value={wizardState}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizardContextState = () => {
  const context = React.useContext(WizardContext);

  if (context === undefined) {
    throw new Error("useWizardContextState must be within a RouteProvider");
  }
  return context;
};
