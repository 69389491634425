import React from "react";
import { returnFit } from "../../services/sportsBraProcessingService";
import BraFittingTips from "./braFittingTips";
import { FaStickyNote } from "react-icons/fa";
import styles from "./fittingYourBra.module.css";

export const FittingYourBra = (props) => {
  const { breastSupportRecommended } = props;

  const braFittingTips = returnFit(breastSupportRecommended);

  return (
    <div>
      <div>
        <ol>
          <li className={styles.liHeader}>
            Fit the band first:
            <ol type="a">
              <li>Do the bra band up on the loosest clip</li>
              <li>Make sure all of your breast is in each cup</li>
              <li>
                If it is too big (band moves upwards or forwards on your trunk
                when you raise your arms) – go down a band size
              </li>
              <li>
                If it is too small (uncomfortable or feels too tight) - go up a
                band size
              </li>
            </ol>
          </li>
          <li className={styles.liHeader}>
            Adjust the length of the straps:
            <ol type="a">
              <li>If too long (straps slip off) – shorten them</li>
              <li>
                If too short (uncomfortable/digging into your shoulders) –
                lengthen them
              </li>
            </ol>
          </li>
          <li className={styles.liHeader}>
            Check cups:
            <ol type="a">
              <li>
                If too big (gaps or wrinkles in the cup) – go down a cup size
              </li>
              <li>
                If too small (breast is bulging above the cup or in your armpit)
                – go up a cup size
              </li>
            </ol>
          </li>
          <li className={styles.liHeader}>
            Check underwire:
            <ol type="a">
              <li>
                If it does not fit (underwire is sitting on your breast tissue
                instead of your breastbone and ribs) – choose a different bra
                style
              </li>
            </ol>
          </li>
        </ol>
        <div className={styles.emphasis}>
          <div className={styles.noteIndicator}>
            <FaStickyNote size="20px" />
          </div>
          <div className={styles.note}>
            <div>For a crop top: skip Steps 1a, 2 and 4; </div>
            <div>For a hybrid bra and non-underwire bra: skip Step 4</div>
          </div>
        </div>
      </div>
      <div>
        <BraFittingTips
          recommended={breastSupportRecommended}
          tips={braFittingTips}
        />
      </div>
    </div>
  );
};

export default FittingYourBra;
