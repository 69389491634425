import React from "react";
import { useWizardContextState } from "../../context/wizardContext";
import styles from "./checkBraSupport.module.css";

export const CheckBraSupport = (props) => {
  const { cup } = useWizardContextState();

  const { breastSupportRecommended } = props;

  let videoSrcAcceptable = "";
  let videoSrcExcessive = "";
  let videoPosterAcceptable = "";
  let videoPosterExcessive = "";

  console.log(breastSupportRecommended);

  if (
    breastSupportRecommended[0].name ===
    "Encapsulation Sports Bra AND Crop Top Sports Bra"
  ) {
    videoSrcAcceptable = require(`../../assets/video/LargeSBCTAcceptableMovementEdited.mp4`);
    videoSrcExcessive = require(`../../assets/video/LargeSBCTExcessiveMovementEdited.mp4`);
    videoPosterAcceptable = require("../../assets/video/LargeSBCTAcceptableMovementEdited.png");
    videoPosterExcessive = require("../../assets/video/LargeSBCTExcessiveMovementEdited.png");
  } else if (breastSupportRecommended[0].name.includes("Crop")) {
    videoSrcAcceptable = require(`../../assets/video/SmallCTAcceptableMovementEdited.mp4`);
    videoSrcExcessive = require(`../../assets/video/SmallCTExcessiveMovementEdited.mp4`);
    videoPosterAcceptable = require("../../assets/video/SmallCTAcceptableMovementEdited.png");
    videoPosterExcessive = require("../../assets/video/SmallCTExcessiveMovementEdited.png");
  } else if (breastSupportRecommended[0].name.includes("Encapsulation")) {
    if (cup < "C") {
      videoSrcAcceptable = require(`../../assets/video/SmallSBAcceptableMovementEdited.mp4`);
      videoSrcExcessive = require(`../../assets/video/SmallSBExcessiveMovementEdited.mp4`);
      videoPosterAcceptable = require("../../assets/video/SmallSBAcceptableMovementEdited.png");
      videoPosterExcessive = require("../../assets/video/SmallSBExcessiveMovementEdited.png");
    } else {
      videoSrcAcceptable = require(`../../assets/video/MediumSBAcceptableMovementEdited.mp4`);
      videoSrcExcessive = require(`../../assets/video/MediumSBExcessiveMovementEdited.mp4`);
      videoPosterAcceptable = require("../../assets/video/MediumSBAcceptableMovementEdited.png");
      videoPosterExcessive = require("../../assets/video/MediumSBExcessiveMovementEdited.png");
    }
  }

  return (
    <div className={styles.checkSupportContainer}>
      <div className={styles.supportiveBraInfo}>
        <div className={styles.movement}>
          Your bra is supportive if you have: 
        </div>
        <ul>
          <li>
            NO <strong>breast discomfort or pain</strong> when you exercise, and 
          </li>
          <li>
            NO substantial <strong>breast movement</strong> when you exercise 
          </li>
        </ul>
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.videoItem}>
          <h2 className={styles.movement}>Acceptable Movement</h2>
          <video
            poster={videoPosterAcceptable}
            id="acceptableMovement"
            width="100%"
            height="100%"
            preload="metadata"
            controls
            playsinline="playsinline"
            autoPlay
            loop
            muted
          >
            <source src={videoSrcAcceptable} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.videoItem}>
          <h2 className={styles.movement}>Excessive Movement</h2>
          <video
            poster={videoPosterExcessive}
            id="excessiveMovement"
            width="100%"
            height="100%"
            preload="metadata"
            controls
            playsinline="playsinline"
            autoPlay
            loop
            muted
          >
            <source src={videoSrcExcessive} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default CheckBraSupport;
