import React from "react";
import styles from "./disclaimer.module.css";

export const Disclaimer = () => {
  return (
    <div className={styles.disclaimer}>
      <p>
        ©Copyright 2021 Breast Research Australia, University of Wollongong.
        This work is protected by the laws of Copyright. Except for any uses
        permitted under the Copyright Act 1968 (Cth) or equivalent overseas
        legislation, no part of this work may be reproduced, in any manner or in
        any medium without the written permission of the publisher. All right
        reserved.
      </p>
      <p>
        Disclaimer: The information provided within this resource is general in
        nature and is not a substitute for medical advice. You use this resource
        at your own risk and no party involved in producing this resource
        accepts any responsibility for how you use the information.
      </p>
    </div>
  );
};

export default Disclaimer;
