import React from "react";
import ChooseYourBra from "../components/SportsBra/chooseYourBra";
import homeStyles from "./home.module.css";
import { EXTERNALSITES } from "../constants/externalSites";

const Home = () => {
  return (
    <div className={homeStyles.container}>
      <div className={homeStyles.content}>
        <div className={homeStyles.textContainer}>
          <div className={homeStyles.textContentContainer}>
            <h1 className={homeStyles.tagLine}>
              <span>An ill-fitting sports bra could be all that’s </span>
              <span>standing between you and your sport!</span>
            </h1>
          </div>
          <div className={homeStyles.logoContainer}>
            <a
              href={EXTERNALSITES.AIS}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={homeStyles.image}
                src={require("../assets/AIS_Gold_Stacked.png")}
                alt="AIS Logo"
              />
            </a>
            <a
              href={EXTERNALSITES.UOW}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={homeStyles.image}
                src={require("../assets/uowLogo.png")}
                alt="UOW Logo"
              />
            </a>
          </div>
        </div>
        <div className={homeStyles.form}>
          <ChooseYourBra wizardHeading={"Tell us about your usual bra size"}>
            A well-fitted and supportive bra allows women to exercise in greater
            comfort and reduces exercise-induced pain by up to 85%.
          </ChooseYourBra>
        </div>
      </div>
    </div>
  );
};

export default Home;
