import React from "react";
import { useWizardContextState } from "../../../context/wizardContext";
import styles from "./wizardStep.module.css";

export const WizardStep = (props) => {
  const { setCurrentStep, currentStep } = useWizardContextState();

  return (
    <div
      className={styles.wizardStepContainer}
      onClick={() => {
        setCurrentStep(props.step);
      }}
    >
      {currentStep === props.step || currentStep === props.stepToDisplay ? (
        <div className={styles.wizardStepNumberActive}>
          <span>{props.stepToDisplay ? props.stepToDisplay : props.step}</span>
        </div>
      ) : (
        <div className={styles.wizardStepNumber}>
          <span>{props.stepToDisplay ? props.stepToDisplay : props.step}</span>
        </div>
      )}
      <div className={styles.wizardStepText}>{props.children}</div>
    </div>
  );
};

export default WizardStep;
