import React from "react";
import containerStyles from "./container.module.css";

const Container = (props) => {
  return (
    <div className={containerStyles.container}>
      <div className={containerStyles.content}>{props.children}</div>
    </div>
  );
};

export default Container;
