import {
  correctFitBraDetails,
  recommendedBra,
  tooBigBraDetails,
  tooSmallBraDetails,
} from "../constants/formUtils";

export const processBraStats = (cup, age, intensity, band) => {
  let breastSupportRecommended = [];
  const {
    HYBRID,
    CROPTOP_SMALL,
    SPORTSBRA_SMALL,
    SPORTSBRA_MEDIUM,
    SPORTSBRA_LARGE,
    SBCT,
  } = recommendedBra;

  if (age === "< 30") {
    if (cup === "A" || cup === "B") {
      if (intensity === "Light" || intensity === "Moderate") {
        breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_SMALL, HYBRID];
      } else {
        breastSupportRecommended = [SPORTSBRA_SMALL, HYBRID];
      }
    }

    if (cup === "C" || cup === "D") {
      if (intensity === "Light") {
        breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_MEDIUM, HYBRID];
      }

      if (intensity === "Moderate" || intensity === "Intense") {
        breastSupportRecommended = [SPORTSBRA_MEDIUM, HYBRID];
      }
    }

    if (cup >= "DD") {
      if (intensity === "Light") {
        breastSupportRecommended = [SPORTSBRA_LARGE, HYBRID];
      }

      if (intensity === "Moderate" || intensity === "Intense") {
        if (
          (band >= 8 && band <= 12) ||
          (band >= 30 && band <= 34) ||
          (band >= 80 && band <= 90)
        ) {
          breastSupportRecommended = [SPORTSBRA_LARGE, HYBRID];
        } else {
          breastSupportRecommended = [SBCT];
        }
      }
    }
  }

  if (age === "30 to 45") {
    if (cup === "A" || cup === "B") {
      if (intensity === "Light") {
        breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_SMALL, HYBRID];
      }

      if (intensity === "Moderate" || intensity === "Intense") {
        breastSupportRecommended = [SPORTSBRA_SMALL, HYBRID];
      }
    }

    if (cup === "C" || cup === "D") {
      if (intensity === "Light") {
        breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_MEDIUM, HYBRID];
      }

      if (intensity === "Moderate" || intensity === "Intense") {
        breastSupportRecommended = [SPORTSBRA_MEDIUM, HYBRID];
      }
    }

    if (cup >= "DD") {
      if (intensity === "Light") {
        breastSupportRecommended = [SPORTSBRA_LARGE, HYBRID];
      }

      if (intensity === "Moderate" || intensity === "Intense") {
        if (
          (band >= 8 && band <= 12) ||
          (band >= 30 && band <= 34) ||
          (band >= 80 && band <= 90)
        ) {
          breastSupportRecommended = [SPORTSBRA_LARGE, HYBRID];
        } else {
          breastSupportRecommended = [SBCT];
        }
      }
    }
  }

  if (age === "> 45") {
    if (cup === "A" || cup === "B") {
      if (intensity === "Light") {
        breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_SMALL, HYBRID];
      } else {
        breastSupportRecommended = [SPORTSBRA_SMALL, HYBRID];
      }
    }

    if (cup === "C" || cup === "D") {
      if (intensity === "Light") {
        breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_MEDIUM, HYBRID];
      } else {
        breastSupportRecommended = [SPORTSBRA_MEDIUM, HYBRID];
      }
    }

    if (cup >= "DD") {
      if (intensity === "Light") {
        breastSupportRecommended = [SPORTSBRA_LARGE, HYBRID];
      }

      if (intensity === "Moderate" || intensity === "Intense") {
        if (
          (band >= 8 && band <= 10) ||
          (band >= 30 && band <= 32) ||
          (band >= 80 && band <= 85)
        ) {
          breastSupportRecommended = [SPORTSBRA_LARGE, HYBRID];
        } else {
          breastSupportRecommended = [SBCT];
        }
      }
    }
  }

  // AA - any age
  if (cup === "AA") {
    if (intensity === "Light") {
      breastSupportRecommended = [CROPTOP_SMALL];
    }

    if (intensity === "Moderate" || intensity === "Intense") {
      breastSupportRecommended = [CROPTOP_SMALL, SPORTSBRA_SMALL];
    }
  }

  return breastSupportRecommended;
};

export const returnFit = (breastSupportRecommended) => {
  let tooSmall = [];
  let tooBig = [];
  let correctFit = [];

  if (breastSupportRecommended.length === 1) {
    if (
      breastSupportRecommended[0].imageInfo[0].src.includes("croptop-small")
    ) {
      correctFit = [correctFitBraDetails.CROPTOP_SMALL];
      tooSmall = [tooSmallBraDetails.CT_SMALL_MEDIUM];
      tooBig = [tooBigBraDetails.CT_SMALL_MEDIUM];
    } else {
      correctFit = [correctFitBraDetails.SBCT];
      tooSmall = [tooSmallBraDetails.CT_LARGE, tooSmallBraDetails.SB_LARGE];
      tooBig = [tooBigBraDetails.CT_LARGE, tooBigBraDetails.SB_LARGE];
    }
  }
  if (breastSupportRecommended.length === 2) {
    if (breastSupportRecommended[0].imageInfo[0].src.includes("croptop")) {
      tooSmall = [
        tooSmallBraDetails.CT_SMALL_MEDIUM,
        tooSmallBraDetails.SB_SMALL_MEDIUM,
      ];
      tooBig = [tooBigBraDetails.CT_SMALL_MEDIUM, tooBigBraDetails.SB_SMALL];
      correctFit = [
        correctFitBraDetails.CROPTOP_SMALL,
        correctFitBraDetails.SPORTSBRA_SMALL,
      ];
    } else {
      if (breastSupportRecommended[0].imageInfo[0].src.includes("small")) {
        tooSmall = [tooSmallBraDetails.SB_SMALL_MEDIUM];
        tooBig = [tooBigBraDetails.SB_SMALL];
        correctFit = [correctFitBraDetails.SPORTSBRA_SMALL];
      } else if (
        breastSupportRecommended[0].imageInfo[0].src.includes("medium")
      ) {
        tooSmall = [tooSmallBraDetails.SB_SMALL_MEDIUM];
        tooBig = [tooBigBraDetails.SB_MEDIUM];
        correctFit = [correctFitBraDetails.SPORTSBRA_MEDIUM];
      } else {
        tooSmall = [tooSmallBraDetails.SB_LARGE];
        tooBig = [tooBigBraDetails.SB_LARGE];
        correctFit = [correctFitBraDetails.SPORTSBRA_LARGE];
      }
    }
  }
  if (breastSupportRecommended.length === 3) {
    if (breastSupportRecommended[0].imageInfo[0].src.includes("-small")) {
      tooSmall = [
        tooSmallBraDetails.CT_SMALL_MEDIUM,
        tooSmallBraDetails.SB_SMALL_MEDIUM,
      ];
      tooBig = [tooBigBraDetails.CT_SMALL_MEDIUM, tooBigBraDetails.SB_SMALL];
      correctFit = [
        correctFitBraDetails.CROPTOP_SMALL,
        correctFitBraDetails.SPORTSBRA_SMALL,
      ];
    } else if (
      breastSupportRecommended[0].imageInfo[0].src.includes("-medium")
    ) {
      tooSmall = [
        tooSmallBraDetails.CT_SMALL_MEDIUM,
        tooSmallBraDetails.SB_SMALL_MEDIUM,
      ];
      tooBig = [tooBigBraDetails.CT_SMALL_MEDIUM, tooBigBraDetails.SB_MEDIUM];
      correctFit = [
        correctFitBraDetails.CROPTOP_SMALL,
        correctFitBraDetails.SPORTSBRA_MEDIUM,
      ];
    } else {
      tooSmall = [tooSmallBraDetails.CT_LARGE, tooSmallBraDetails.SB_LARGE];
      tooBig = [tooBigBraDetails.CT_LARGE, tooBigBraDetails.SB_LARGE];
      correctFit = [
        correctFitBraDetails.CROPTOP_SMALL,
        correctFitBraDetails.SPORTSBRA_LARGE,
      ];
    }
  }

  return { tooSmall: tooSmall, tooBig: tooBig, correctFit: correctFit };
};
