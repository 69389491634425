import React from "react";
import { EXTERNALSITES } from "../constants/externalSites";
import about from "../assets/about.jpg";
import styles from "./about.module.css";
import Disclaimer from "../components/disclaimer";

const AboutPage = () => {
  return (
    <div className={styles.aboutPage}>
      <div className={styles.aboutHeader}>
        <h1 className={styles.aboutHeaderTitle}>Stay Active with Sports Bra</h1>
        <div className={styles.aboutHeaderSubtitle}>
          <h2>
            The Sports Bra tool contains evidence-based information to help you
            choose a sports bra that is well-fitted, supportive and, most
            importantly, comfortable.
          </h2>
        </div>
      </div>

      <div className={styles.aboutContainer}>
        <div className={styles.containerLeft}>
          <div>
            <p>
              Breast Research Australia has been researching breast support and
              bra fit for over 20 years.
            </p>
          </div>
          <div>
            <p>
              To allow women to exercise in comfort, our research has shown that
              sports bras must be 
              <strong> designed properly AND fitted correctly.</strong>
            </p>
          </div>
          <div>
            <p>
              Finding the right sports bra – in the right size, however, is
              difficult and many women get it wrong!
            </p>
          </div>
          <div>
            <p>
              This tool is supported by the{" "}
              <a
                href={EXTERNALSITES.AIS}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Australian Institute of Sport</span>
              </a> 
              {" "}and aims to empower women of all ages, breast sizes and activity
              levels to independently choose a 
              <strong> sports bra design</strong> that provides adequate breast
              support and fits correctly so that all women can exercise in
              comfort.
            </p>
          </div>
        </div>

        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={about}
            alt="Associate Professor Deirdre McGhee and Professor Julie Steele using the Sports Bra app"
          />
          <p>
            You can read more about the people behind the app over at the{" "}
            <a
              href={EXTERNALSITES.BRA}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Breast Research Australia website.</span>
            </a>
          </p>
        </div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default AboutPage;
