import React from "react";
import styles from "./about.module.css";
import ContactForm from "../components/contactForm";

const Contact = () => {
  return (
    <div className={styles.aboutPage}>
      <div className={styles.aboutHeader}>
        <h1 className={styles.aboutHeaderTitle}>Let us know what you think</h1>
        <div className={styles.aboutHeaderSubtitle}>
          <h2>
            Your feedback is valuable to us! <br />
            Please let us know if this application has been helpful to you.
          </h2>
        </div>
      </div>

      <ContactForm />
    </div>
  );
};

export default Contact;
